// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles/main.css'
import './styles/fonts.css'
import './index.html'
import './images/sl-1-h.png'
import './images/sl-1-m.png'
import './images/main-bg-2.png'

function modalOpen(modal) {

  modal.classList.add('active');
  document.body.style.overflow = 'hidden';
}

function modalClose(modal) {

  modal.classList.remove('active');
  document.body.style.overflow = 'visible';
}

function checkboxValue(checkbox) {

  let errorMessage = checkbox.closest('.c-checkbox');

  if(errorMessage) {

    errorMessage = errorMessage.querySelector('.peer-invalid')

    if(errorMessage) {

      if (checkbox.checked) {
        errorMessage.classList.add('hidden');
      } else {

        errorMessage.classList.remove('hidden');

        return true;
      }
    }
  }
}

window.checkboxValue = checkboxValue;
window.modalOpen = modalOpen;
window.modalClose = modalClose;

document.addEventListener('DOMContentLoaded', () => {

    const customSelects = document.querySelectorAll('.custom-select');

    customSelects.forEach((customSelect) => {
        const selected = customSelect.querySelector('.select-selected');
        const options = customSelect.querySelector('.select-options');
        const originalSelect = customSelect.querySelector('select');

        // Обработчик для отображения/скрытия выпадающего списка
        selected.addEventListener('click', () => {
            customSelects.forEach((cs) => {
                if (cs !== customSelect) {
                    cs.classList.remove('active');
                }
            });
            customSelect.classList.toggle('active');
        });

        // Обработчик выбора элемента из списка
        options.addEventListener('click', (event) => {
            if (event.target.tagName === 'LI') {
                const value = event.target.dataset.value;
                const text = event.target.textContent;

                // Отобразить выбранный вариант
                selected.textContent = text;

                // Установить значение в оригинальный select
                originalSelect.value = value;

                // Скрыть список
                customSelect.classList.remove('active');
            }
        });
    });

    // Закрытие всех выпадающих списков при клике вне элемента
    document.addEventListener('click', (event) => {
        customSelects.forEach((customSelect) => {
            if (!customSelect.contains(event.target)) {
                customSelect.classList.remove('active');
            }
        });
    });

    const submenuShowButton = document.querySelectorAll('.submenu-show-button');

    submenuShowButton.forEach((button) => {

        button.addEventListener('click', (e) => {
          
          e.preventDefault();

          submenuShowButton.forEach(function(button){

            button.classList.remove('active');
          });

          button.classList.add('active');

          let allSubmenu = document.querySelectorAll('.menu-header-content');
          let submenu = button.getAttribute('data-submenu');
          submenu = document.querySelector('.menu-header-content#' + submenu);

          allSubmenu.forEach(function(submenu){

            submenu.classList.remove('active');
          });

          submenu.classList.add('active');
        });
    });

    function clickModalButton(button) {

      let modalId = button.getAttribute('data-modal');
      let modal = document.querySelector('.modal#' + modalId);

      modalOpen(modal);
    }

    function clickModalClose(e, modal) {

      if(e.target.classList.contains('over-bg') || e.target.classList.contains('modal-content-area') || e.target.classList.contains('close-modal') || e.target.closest('.close-modal')) {
        
        modalClose(modal);
      }
    }

    const modalMenuButton = document.querySelectorAll('.modal-menu-button');

    modalMenuButton.forEach((button) => {

      button.addEventListener('click', () => {
        clickModalButton(button);
      });
    });

    const modal = document.querySelectorAll('.modal');

    modal.forEach((modalWindow) => {

      modalWindow.addEventListener('click', (e) => {

        clickModalClose(e, modalWindow);
      });
    });

    const header = document.getElementById('header');
    const left_menu = document.getElementById('left_menu');

    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        header.classList.add('fixed-area');
        //left_menu.classList.add('fixed');
      } else {
        header.classList.remove('fixed-area');
        //left_menu.classList.remove('fixed');
      }
    });


    function adjustSwiperHeight(swiperContainer) {
      const slides = swiperContainer.querySelectorAll('.swiper-slide');
      let maxHeight = 0;

      // Определяем максимальную высоту среди слайдов
      slides.forEach(slide => {
        slide.style.height = 'auto'; // Сбрасываем высоту, чтобы она определилась автоматически
        const slideHeight = slide.offsetHeight;
        if (slideHeight > maxHeight) {
          maxHeight = slideHeight;
        }
      });

      // Устанавливаем высоту для контейнера
      swiperContainer.style.height = `${maxHeight}px`;
    }

    if(document.querySelector('#main-slider')) {
      
      const mainSlider = new Swiper('#main-slider', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        navigation: true, // Enable navigation
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // Enable clickable pagination
        },
        on: {
          init: () => {
            adjustSwiperHeight(document.querySelector('#main-slider'));
          }, // Вызываем функцию при инициализации
          slideChangeTransitionEnd: adjustSwiperHeight, // Вызываем при смене слайдов
        },
      });
    }

    if(document.querySelector('#banner-slider')) {
      
      const mainSlider = new Swiper('#banner-slider', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        modules: [Pagination],
        slidesPerView: 1,
        pagination: {
          el: '.banner-swiper-pagination',
          clickable: true, // Enable clickable pagination
        },
      });
    }

    if(document.querySelector('#case-list-slider')) {

      const caseListSlider = new Swiper('#case-list-slider', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: true, // Enable navigation
        pagination: {
          el: '.case-swiper-pagination',
          clickable: true, // Enable clickable pagination
        },
        breakpoints: {
          // Ширина экрана ≥ 640px
          // Ширина экрана ≥ 640px
          767: {
            slidesPerView: 2, // Показывать 2 слайда
            slidesPerGroup: 2,
          },
        },
        on: {
          init: () => {
            adjustSwiperHeight(document.querySelector('#case-list-slider'));
          }, // Вызываем функцию при инициализации
          slideChangeTransitionEnd: adjustSwiperHeight, // Вызываем при смене слайдов
        },
      });
    }

    if(document.querySelector('#post-list-slider')) {

      const caseListSlider = new Swiper('#post-list-slider', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
          // Ширина экрана ≥ 640px
          // Ширина экрана ≥ 640px
          767: {
            slidesPerView: 2, // Показывать 2 слайда
            slidesPerGroup: 2,
          },
          1607: {
            slidesPerView: 4, // Показывать 2 слайда
            slidesPerGroup: 4,
          },
        },
      });

      const nextButton = document.querySelector('.post-next-btn');

      nextButton.addEventListener('click', () => {
        caseListSlider.slidePrev(); // Перелистывание на предыдущий слайд
      });
    }

    if(document.querySelector('#feature-slider')) {

      const featureSlider = new Swiper('#feature-slider', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: true, // Enable navigation
        pagination: {
          el: '.feature-swiper-pagination',
          clickable: true, // Enable clickable pagination
        },
        breakpoints: {
          // Ширина экрана ≥ 640px
          // Ширина экрана ≥ 640px
          767: {
            slidesPerView: 2, // Показывать 2 слайда
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3, // Показывать 3 слайда
            slidesPerGroup: 3,
          },
          1607: {
            slidesPerView: 4, // Показывать 4 слайда
            slidesPerGroup: 4,
          },
        },
        on: {
          init: () => {
            adjustSwiperHeight(document.querySelector('#feature-slider'));
          }, // Вызываем функцию при инициализации
          slideChangeTransitionEnd: adjustSwiperHeight, // Вызываем при смене слайдов
        },
      });
    }

    const checkboxes = document.querySelectorAll('[name="privacy_policy"]');

    if(checkboxes) {

      checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {

          checkboxValue(checkbox);
        });
      });
    }

    const leftMenuLinks = document.querySelectorAll('.left-menu a');

    leftMenuLinks.forEach((link2) => {

      link2.addEventListener('click', (e) => {
        
        leftMenuLinks.forEach(function(link3){

          link3.classList.remove('active');
        });

        link2.classList.add('active');
      });
    });

    let scene1 = document.getElementById('scene-1');
    let scene2 = document.getElementById('scene-2');

    if(scene1 && scene2 && window.innerWidth > 767) {

      // Получаем сцены
      var scenes = [scene1, scene2];

      // Создаем экземпляры Parallax для каждой сцены
      var parallaxInstances = scenes.map(scene => new Parallax(scene));
    }

    var e=[].slice.call(document.querySelectorAll("img[loading=lazy]")),t=[].slice.call(document.querySelectorAll(".lazy-bg"));if(e.length>0){let t=new IntersectionObserver((function(e,l){e.forEach((function(e){if(e.isIntersecting){let l=e.target;l.src=l.dataset.src,t.unobserve(l)}}))}));e.forEach((function(e){t.observe(e)}))}t.length>0&&(console.log(t),t.forEach((function(e){let t=e;t.classList.remove("lazy-bg"),t.style.backgroundImage="url("+t.dataset.src+")",console.log(t.dataset.src)})));
});

  